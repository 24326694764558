<template>
  <div class="wrapper">
    <Toast v-if="alarmas" @click="visible = !visible;test()" v-model="isVisible" ref="tost" />
    <Alarmas :alarmas="alarmas" v-on:actualizarAlarmas="cargaralarmas" :visible="visible"></Alarmas>
    <the-top :longitud="longitud" :trabajos="trabajos" :numero_trabajos="numero_trabajos" :solicitudes="solicitudes"
      :numero_solicitudes="numero_solicitudes" :asignaciones="asignaciones"
      :numero_asignaciones="numero_asignaciones" 
      :payload="payload"></the-top>
    <the-sidebar :menu="menu"></the-sidebar>
    <div class="content-wrapper">
      <!-- De momento, no mostramos la cabecera con el breadcrumbs para el sistema de multiventanas -->
      <the-content-header v-if="false"></the-content-header>
      <div class="card flex" v-if="extendido==true">
        <div class="row">
          <div class="col-md-4">
            <div class="row" style="border-bottom: 1px solid;">
              <div class="col-md-5"></div>
              <b style="text-align:center;margin-right: 10px;">Asignaciones</b>
              <input @click="cambiarUrgentes" v-model="asignacionesUrgentes" type="checkbox">
            </div>
            <div class="row" style="overflow: auto; height:200px;border: 1px solid">
              <ListaAsigaciones :asignaciones="asignaciones" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="row" style="border-bottom: 1px solid;">
              <div class="col-md-5"></div>
              <b style="text-align:center">Solicitudes</b>
            </div>
            <div class="row" style="overflow: auto; height:200px;border: 1px solid">
              <ListaSolicitudes :solicitudes="solicitudes" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="row" style="border-bottom: 1px solid;">
              <div class="col-md-5"></div>
              <b style="text-align:center">Trabajos NO revisados</b>
            </div>
            <div class="row" style="overflow: auto; height:200px;border: 1px solid">
              <ListaTrabajos :trabajos="trabajos" />
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container-fluid">
          <multi-ventanas>
            <router-view v-slot="{ Component }">
              <transition name="fade">
                <keep-alive :max="1">
                  <component :is="Component" />
                </keep-alive>
              </transition>
            </router-view>
          </multi-ventanas>
        </div>
      </div>
    </div>
    <the-footer></the-footer>
  </div>
</template>
<script>
import TheTop from './TheTop.vue'
import TheSidebar from './TheSidebar.vue'
import TheFooter from './TheFooter.vue'
import TheContentHeader from './TheContentHeader.vue'
import MultiVentanas from './multiventanas/MultiVentanas.vue'
import { PwgsApi } from "../services/PwgsApi";
import Alarmas from './Alarmas.vue';
import ListaAsigaciones from './pages/menus/superior/asignaciones/AsignacionesLista.vue';
import ListaTrabajos from './pages/menus/superior/TrabajosNoRevisados/TrabajosNoRevisadosLista.vue';
import ListaSolicitudes from './pages/menus/superior/solicitudes/SolicitudesLista.vue';
import { onMessage, messaging } from '@/firebase';


export default {
  components: {
    TheTop,
    TheSidebar,
    TheFooter,
    TheContentHeader,
    MultiVentanas,
    Alarmas,
    ListaAsigaciones,
    ListaTrabajos,
    ListaSolicitudes
  },
  data() {
    return {
      menu: [],
      alarmas: '',
      longitud: 0,
      visible: false,
      isVisible: true,
      trabajos: [],
      numero_trabajos: '',
      solicitudes: [],
      numero_solicitudes: '',
      asignaciones: [],
      numero_asignaciones: '',
      extendido: true,
      asignacionesUrgentes: false,
      payload: null
    }
  },
  methods: {
    async cargaralarmas() {
        const api = new PwgsApi();
        const apidos = new PwgsApi();

      try{
        this.alarmas = await api.get('alarmas');
      }
      catch(error){
        console.log("error345", error);
        if (error == "Error: Token no válido") {
          apidos.get('logout');
          localStorage.removeItem('token');
          this.$router.push('/login');
        }
      }
      this.longitud = this.alarmas.length;
      },
      test() {
        this.$toast.removeAllGroups();
    },
    cargartoast() {
    this.cargaralarmas();
    if (this.longitud>0) {
      this.$toast.add({ severity: 'warn', summary: 'Alarmas', detail: 'Tienes ' + this.longitud + ' alarma/s por contestar', life: 58000 * 1 });

    }
    setInterval(() => {
      this.cargaralarmas();
      if (this.alarmas) {
        this.$toast.add({ severity: 'warn', summary: 'Alarmas', detail: 'Tienes ' + this.longitud + ' alarma/s por contestar', life: 58000 * 1 });
        
      }
    }, 50000 * 2);
    },
    cargarvisible() {
      this.visible = false;
    },
    togglevisible() {
      this.visible = !this.visible;
    },
    async cargartrabajos() {
      /* get  modulos/pwgsapi/index.php/avisos/trabajos-no-revisados */
      const api = new PwgsApi;
      const datos = await api.post('avisos/trabajos-no-revisados',{'sortField':'fecha_actualiza_mensaje', 'sortOrder':'1'});
      this.trabajos = datos.datos;
      this.numero_trabajos = datos.n_total_registros;
      localStorage.setItem("num_trabajos", this.numero_trabajos);
    },
    async cambiarUrgentes() {
      const api = new PwgsApi;
      if(this.asignacionesUrgentes==false){
        const datos = await api.post('avisos/asignaciones',{'sortField':'fecha_crear_servicio', 'sortOrder':'1',"urgente":1});
        this.asignaciones = datos.datos;
        this.numero_asignaciones = datos.n_total_registros;
        localStorage.setItem("num_asignaciones", this.numero_asignaciones);
      }else{
        this.cargarasignaciones();
      }
    },
    async cargarsolicitudes() {
      /* get  modulos/pwgsapi/index.php/avisos/solicitudes */
      const api = new PwgsApi;
      const datos = await api.post('avisos/solicitudes',{'sortField':'fecha_actualiza_mensaje_cache', 'sortOrder':'0'});
      this.solicitudes = datos.datos;
      this.numero_solicitudes = datos.n_total_registros;
      localStorage.setItem("num_solicitudes", this.numero_solicitudes);
    },
    async cargarasignaciones() {
      /* get  modulos/pwgsapi/index.php/avisos/asignaciones */
      const api = new PwgsApi;
      const datos = await api.post('avisos/asignaciones',{'sortField':'fecha_crear_servicio', 'sortOrder':'1'});
      this.asignaciones = datos.datos;
      this.numero_asignaciones = datos.n_total_registros;
      localStorage.setItem("num_asignaciones", this.numero_asignaciones);


    },
    async salir(){
      const api = new PwgsApi();
      await api.get('logout');
 
    },
    cargartodo() {
      try{
      setInterval(() => {
      this.cargarasignaciones();
      this.cargarsolicitudes();
        this.cargartrabajos(); 
      }, 60000 * 2);
      }
      catch (error) {
        console.log("error123", error);
        if (error == "Error: Token no válido") {
          console.log("error345556");
          this.salir();
          this.$router.push('/login');
        }
      }
    },
    mostrarextendido() {
      this.extendido = !this.extendido;
    },
    monitorizarErrores(){

        var initOpts = {
            projectKey: "rMVhQP7dYnFONci3F4h7",
            ingestPoint: "https://monitorapp.grupoapuyen.net/ingest",
            defaultInputMode: 0,
            obscureTextNumbers: false,
            obscureTextEmails: true,
        };
        var startOpts = { userID: localStorage.getItem("nombreusuario")+"@"+localStorage.getItem("subdomain") }; 
        (function(A,s,a,y,e,r){
            r=window.OpenReplay=[e,r,y,[s-1, e]];
            s=document.createElement('script');s.src=A;s.async=!a;
            document.getElementsByTagName('head')[0].appendChild(s);
            r.start=function(){r.push([0])};
            r.stop=function(){r.push([1])};
            r.setUserID="function(id){r.push([2,id])}";
            r.setUserAnonymousID="function(id){r.push([3,id])}";
            r.setMetadata=function(k,v){r.push([4,k,v])};
            r.event=function(k,p,i){r.push([5,k,p,i])};
            r.issue=function(k,p){r.push([6,k,p])};
            r.isActive=function(){return false};
            r.getSessionToken=function(){};
        })("//static.openreplay.com/13.0.0/openreplay-assist.js",1,0,initOpts,startOpts);
    },

  },
  mounted() {
    

    this.cargartoast();
    this.cargarasignaciones();
    this.cargarsolicitudes();
    this.cargartrabajos(); 
    this.cargartodo();
    this.monitorizarErrores();
  },
  created() {
    this.menu = this.$store.getters.menu;
    onMessage(messaging, (payload) => {
        console.log('Notificación recibida:', payload);
        this.payload = payload;  
        //alert('Notificación recibida:');
        // Maneja la notificación 
    });
  }

}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>